// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-genre-js": () => import("./../../../src/templates/genre.js" /* webpackChunkName: "component---src-templates-genre-js" */),
  "component---src-templates-todaytomorrow-js": () => import("./../../../src/templates/todaytomorrow.js" /* webpackChunkName: "component---src-templates-todaytomorrow-js" */),
  "component---src-templates-venue-js": () => import("./../../../src/templates/venue.js" /* webpackChunkName: "component---src-templates-venue-js" */),
  "component---src-templates-yours-js": () => import("./../../../src/templates/yours.js" /* webpackChunkName: "component---src-templates-yours-js" */)
}

