import React from "react"
import { createGlobalStyle } from 'styled-components';

import Header from "./Header";
import BottomNav from "./BottomNav";
import Footer from "./Footer";

import { FirebaseContext, useAuth } from "../Firebase"

const GlobalStyles = createGlobalStyle`
  body {
    font: 400 20px "Helvetica Neue","Helvetica","Arial",sans-serif;
    color: #1d1d1f;
    background-color: #1d1d1f;
    margin: 0;
    overflow-x: hidden;
  }

  @media (min-width:801px) {
    body {
        font: 400 26px "Helvetica Neue","Helvetica","Arial",sans-serif;;
    }
  }

  h1, h2, h3, h4, h5, li, ul, p { margin: 0; }
`

const Layout = ({children}) => {



 

  const { user, firebase, loading } = useAuth()

  // console.log("Layout.js user")
  // console.log(user)

  return(
    <React.Fragment>
      <FirebaseContext.Provider value={{user, firebase, loading}}>
        <GlobalStyles />
        <Header />
        {children}
        <Footer />
        <BottomNav />
      </FirebaseContext.Provider>
    </React.Fragment>
  )
};

export default Layout;