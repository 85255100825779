import { useEffect, useState, useRef } from "react"
import getFirebaseInstance from "./firebase"
import loadFirebaseDependencies from "./loadFirebaseDependencies"
import { useLocation } from "@reach/router";

let firebaseInstance;

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const useAuth = () => {
    const [user, setUser] = useState(null)
    const [firebase, setFirebase] = useState(null)
    const [loading, setLoading] = useState(true)

    //const [open, setOpen] = useState(false);
    const location = useLocation(); 
    const prevLocation = usePrevious(location);

    // NEW
    useEffect(() => {
        if (location !== prevLocation) {
            //setOpen(false);

            loadFirebaseDependencies.then(app => {
                firebaseInstance = getFirebaseInstance(app)
                setFirebase(firebaseInstance)
    
                firebaseInstance.auth.onAuthStateChanged(userResult => {
                    if (userResult) {
                        firebaseInstance.getUserProfile({ 
                            userId: userResult.uid 
                        }).then(snapshot => {
                            if (snapshot.empty) {
                                console.log('No matching documents.');
                            } else {
                                let username = null
                                let bookingNo = null
                                let events = {'1':false}
    
                                //that's shit 
                                snapshot.forEach(doc => {
                                    username = doc.data().username
                                    bookingNo = doc.data().bookingNo
                                    events = doc.data().events
                                })
    
                                setUser({
                                    ...userResult,
                                    username: username, 
                                    bookingNo: bookingNo,
                                    events: events
                                });
                                setLoading(false)
                            } 
                        });
                    }else{
                        setUser(null);
                    }
                    setLoading(false);
                })
            })
        }
    }, [location, prevLocation]);

    return { user, firebase, loading }
}

export default useAuth
