const React = require("react")
const Layout = require("./src/components/Layout/Layout").default

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

exports.onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `Reload to display the latest events?`
  )
  if (answer === true) {
    window.location.reload()
  }
}

