import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const FooterBackgroundColor = styled.div`
    background-color: #52c2f0;
    color: white
`

const FooterWrapper = styled.div`
  padding: 0px;
  margin: 10px;
  @media (min-width:801px) {
    margin: 50px; 
  }
`

const FooterLinks = styled.div`
  padding-top: 16px;
  padding-bottom: 100px;

  ul {
    padding: 0px 16px;
    list-style-type: none;
    line-height: 200%;
  }

  a {
    text-decoration: none;
    color: white;
  }
`

const Footer = () => {
  return (
    <React.Fragment>
      <FooterBackgroundColor>
        <FooterWrapper>
            <FooterLinks>
            <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About / data protection statement</Link>
                </li>
                <li>
                  made with <span role="img" aria-label="cheese">🧀</span> by zrce.eu
                </li>
            </ul>
            </FooterLinks>
        </FooterWrapper>
      </FooterBackgroundColor>
    </React.Fragment>
  );
}

export default Footer