import firebaseConfig from "./config";

class Firebase {
  constructor(app) {
   if(!firebaseInstance) {
      app.initializeApp(firebaseConfig);      
      this.auth = app.auth();
      this.db = app.firestore();
      this.functions = app.functions();
      this.storage = app.storage();
   }
  }

  async getUserProfile({userId}) {  //nur bei refresh durchlaufen 
    const userprofile = await this.db.collection('publicProfiles').where('userId', '==', userId).get();
    return userprofile
  }

  async register({email, password}) { 
    const user = await this.auth.createUserWithEmailAndPassword(email, password);
    return this.db.collection('publicProfiles').doc(user.user.uid).set({
      userId: user.user.uid,
      username: null,
      bookingNo: null,
      events: {'1':false}
    })
  };

  async updateProfile({uid, username, bookingNo, events}) {
    const user = await this.auth.currentUser;
    if(uid === user.uid) {
      if(events !== null) {
        await this.db.collection('publicProfiles').doc(uid).set({
          userId: uid,
          events: events
        }, { merge: true });
      }

      if(username !== null || bookingNo != null) {
        await this.db.collection('publicProfiles').doc(uid).set({
          userId: uid,
          username: username,
          bookingNo: bookingNo,
        }, { merge: true });
      }
    }
  }

  async login({email, password}) {
    return this.auth.signInWithEmailAndPassword(email, password); //that's another user object than update but it has no profil info either
  }

  async passwordReset({email}) {
    if(email === null) {
      let loggedInUser = await this.auth.currentUser;
      email = loggedInUser.email
    }
    await this.auth.sendPasswordResetEmail(email)
  }

  async logout() {
    await this.auth.signOut();
  }

  async deleteAccount({password}) {
    let loggedInUser = await this.auth.currentUser;
    //login again
    await this.auth.signInWithEmailAndPassword(loggedInUser.email, password); //that's another user object than update but it has no profil info either
    await loggedInUser.delete()
  }
}

let firebaseInstance;

function getFirebaseInstance(app) {
  if(!firebaseInstance && app){
    firebaseInstance = new Firebase(app);
    return firebaseInstance;
  }else if(firebaseInstance){
    return firebaseInstance
  }else{
    return null;
  }
}

export default getFirebaseInstance;
