import React, { useContext } from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
import CookieConsent from "react-cookie-consent";
import { FirebaseContext } from "../Firebase"

const MenuBar = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #52c2f0;
  color: white;

  a {
    text-decoration: none;
    color: #ffffff;
  }
`;

const MenuName = styled.div`
  padding: 10px;
  @media (min-width: 801px) {
    padding-left: 50px;
  }
`;

const LoginArea = styled.div`
  padding: 10px;
  background-color: #FCC02E;
`;

const LogoutArea = styled.div`
  padding: 10px;
  background-color: #000000;
`;

const Header = () => {
  const { user, firebase } = useContext(FirebaseContext)

  const handleLogoutClick = () => {
    firebase.logout().then(() => navigate("/"))
  }  

  return (
    <React.Fragment>
      <MenuBar>
        <MenuName>
          <Link to="/">zrce.app</Link>
        </MenuName>
        {(user == null) ? (
          <LoginArea>
            <Link to="/login">Login</Link>
          </LoginArea>
        ) : (
          <LogoutArea>
            <span role="button" tabIndex={0} onClick={handleLogoutClick} onKeyDown={handleLogoutClick}>Logout</span>
          </LogoutArea>
        )}
      </MenuBar>

      <CookieConsent
        location="bottom"
        overlay
        buttonText="Accept Cookies"
        declineButtonText="No"
        enableDeclineButton
        flipButtons
        cookieName="gatsby-gdpr-google-analytics"
        style={{ background: "#f4f4f4", color: "#1d1d1f" }}
        buttonStyle={{ color: "#000000", fontSize: "30px" }}
        declineButtonStyle={{ color: "#000000", fontSize: "30px" }}
        expires={365}
      >
        <p>This Web App uses cookies</p>
        <p>
          <span style={{ fontSize: "12px" }}>
            Some are essencial for usage. Others help us to improve user
            experience. <a href="/about">data protection statement</a>
          </span>
        </p>
      </CookieConsent>
    </React.Fragment>
  );
}

export default Header 