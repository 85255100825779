import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import TodayIcon from "@material-ui/icons/Today";
import PersonIcon from '@material-ui/icons/Person';
import StarIcon from '@material-ui/icons/Star';
import { Link } from "gatsby";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
});

const BottomNav = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <BottomNavigation showLabels className={classes.root}>
        <BottomNavigationAction
          label="Today"
          icon={<TodayIcon />}
          component={Link}
          to="/today"
        />
        <BottomNavigationAction
          label="Your Events"
          icon={<StarIcon />}
          component={Link}
          to="/your-events"
        />
        <BottomNavigationAction
          label="Home"
          icon={<HomeIcon />}
          component={Link}
          to="/"
        />
        <BottomNavigationAction
          label="Profile"
          icon={<PersonIcon />}
          component={Link}
          to="/profile"
        />
      </BottomNavigation>
    </React.Fragment>
  );
}

export default BottomNav